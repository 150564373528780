import {defineStore} from "pinia";
import type {AuthDto} from '@/types/auth/AuthDto';
import {AuthService} from "@/services/auth.service";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as AuthDto | null,
    loading: false,
    error: null as string | null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.user?.accessToken,
    username: (state) => state.user?.username,
  },
  actions: {
    async login(username: string, password: string) {
      this.loading = true;
      this.error = null;
      const authService = new AuthService();

      try {
        const authDto: AuthDto = await authService.login(username, password);
        this.user = authDto;
      } catch (error) {
        if (error instanceof Error) {
          this.error = error.message;
        } else {
          this.error = 'An unexpected error occurred';
        }
      } finally {
        this.loading = false;
      }
    },
    logout() {
      this.user = null;
      this.error = null;
      this.loading = false;
    },
    setAuth(authDto: AuthDto) {
      this.user = authDto;
    },
    clearAuth() {
      this.user = null;
    }
  },
  persist: {
    storage: sessionStorage,
  },
});