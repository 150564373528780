<!-- src/App.vue -->
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
import TenantLayout from './layouts/TenantLayout.vue'
import BlankLayout from './layouts/BlankLayout.vue'

const authStore = useAuthStore()
const authenticated = ref(authStore.isAuthenticated)

watch(
    () => authStore.isAuthenticated,
    (newValue) => {
      authenticated.value = newValue
    },
    { immediate: true }
)

const currentLayout = computed(() => {
  return authenticated.value ? TenantLayout : BlankLayout
})

</script>

<template>
  <component :is="currentLayout">
    <RouterView />
  </component>
</template>

<style scoped>
/* Your scoped styles here */
</style>