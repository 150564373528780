import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from "@/stores/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/dashboard/Dashboard.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/loginView.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logoutView.vue')
    },
    {
      path: '/stripe-onboarding',
      name: 'stripe-onboarding',
      component: () => import('@/views/stripe/StripeView.vue')
    },
    {
      path: '/get-started',
      name: 'get-started',
      component: () => import('@/views/clientOnboard/GetStarted.vue')
    },
    {
      path: '/demo',
      name: 'demo',
      component: () => import('@/views/clientOnboard/PlazaLeadForm.vue')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/clientOnboard/SignUp.vue')
    },
  ]
})

router.beforeEach(async (to) => {
  const authStore = useAuthStore()

  const publicRoutes = ['login', 'forgot-password', 'sign-up', 'get-started', 'demo']

  if (authStore.user === null && !publicRoutes.includes(to.name as string)) {
    return { name: 'login' }
  } else if (authStore.user !== null && to.name === 'login') {
    return { name: 'home' }
  }
})

export default router