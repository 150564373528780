import type { AuthDto } from '@/types/auth/AuthDto'
import type { RegisterApplicantDto } from '@/types/auth/RegisterApplicantDto'
import type { RegisterAccountOwnerDto } from "@/types/clientOnboard/RegisterAccountOwnerDto";


export class AuthService {
  private baseUrl: string = '/api/auth';

  async login(username: string, password: string): Promise<AuthDto> {
    const authDto: AuthDto = {
      username: username,
      pass: password,
      accessToken: '',
      refreshToken: ''
    };

    const response = await fetch(`${this.baseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authDto),
    });

    if (!response.ok) {
      if (response.status === 429) {
        throw new Error('Too many login attempts. Please try again later.');
      }
      throw new Error('Login failed');
    }

    return await response.json() as AuthDto;
  }

  async refreshToken(refreshToken: string): Promise<AuthDto> {
    const response = await fetch(`${this.baseUrl}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Token refresh failed');
    }

    return await response.json() as AuthDto;
  }

  async logout(): Promise<void> {
    const response = await fetch(`${this.baseUrl}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Logout failed');
    }
  }

  async registerApplicant(applicantData: RegisterApplicantDto): Promise<AuthDto> {
    const response = await fetch(`${this.baseUrl}/register-applicant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicantData),
    });

    if (!response.ok) {
      if (response.status === 429) {
        throw new Error('Too many registration attempts. Please try again later.');
      }
      if (response.status === 409) {
        throw new Error('User already exists');
      }
      throw new Error('Registration failed');
    }

    return await response.json() as AuthDto;
  }

  async registerAccountOwner(registerAccountOwnerDto: RegisterAccountOwnerDto): Promise<AuthDto> {
    try {
      const response = await fetch(`${this.baseUrl}/register-owner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerAccountOwnerDto),
      });

      if (!response.ok) {
        const errorBody = await response.text();
        let errorMessage = 'Registration failed';

        switch (response.status) {
          case 400:
            errorMessage = `Bad request: ${errorBody}`;
            break;
          case 409:
            errorMessage = 'User already exists';
            break;
          case 429:
            errorMessage = 'Too many registration attempts. Please try again later.';
            break;
          case 500:
            errorMessage = `Server error: ${errorBody}`;
            break;
          default:
            errorMessage = `Registration failed with status ${response.status}: ${errorBody}`;
        }

        throw new Error(errorMessage);
      }

      return await response.json() as AuthDto;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Registration failed: ${error.message}`);
      }
      throw new Error('An unexpected error occurred during registration');
    }
  }
}