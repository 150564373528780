<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useDisplay } from 'vuetify'

const router = useRouter()
const authStore = useAuthStore()
const { mdAndUp } = useDisplay()

const drawer = ref(true)
const rail = ref(true)

const menuItems = ref([
  { title: 'Dashboard', icon: 'mdi-home', to: '/' },
  { title: 'Payment', icon: 'mdi-cash-fast', to: '/payment' },
  { title: 'Maintenance', icon: 'mdi-pipe-wrench', to: '/maintenance' },
  { title: 'Documents', icon: 'mdi-file-document-check', to: '/documents' },
  { title: 'Contact', icon: 'mdi-email', to: '/contact' },
])

const logout = async () => {
  await authStore.logout()
  await router.push('/login')
}

const chevronIcon = computed(() => rail.value ? 'mdi-chevron-right' : 'mdi-chevron-left')

onMounted(() => {
  rail.value = !mdAndUp.value
})

watch(mdAndUp, (newValue) => {
  rail.value = !newValue
})
</script>

<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        class="bg-primary"
    >
      <v-list-item v-if="!rail" title="Emerald Creek" subtitle="by Plaza" nav>
        <template v-slot:append>
          <v-btn
              :icon="chevronIcon"
              variant="text"
              @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>
      <v-list-item v-else nav>
        <v-btn
            :icon="chevronIcon"
            variant="text"
            @click.stop="rail = !rail"
        ></v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            :to="item.to"
            link
        >
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

      </v-list>
      <template v-slot:append>
        <v-list-item link to="/settings">
          <template v-slot:prepend>
            <v-icon>mdi-cog</v-icon>
          </template>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout" link>
          <template v-slot:prepend>
            <v-icon>mdi-logout</v-icon>
          </template>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>