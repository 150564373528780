<template>
  <v-app class="background-container" style="z-index: 0;">
    <!-- Header -->
<!--    <v-app-bar app flat color="transparent" elevation="0" class="mt-6">-->
      <v-app-bar flat color="transparent" elevation="0" class="mt-6" style="z-index: 3">
      <v-container class="py-0 fill-height ">
        <v-img
            src="/plaza_logo_wordmark.svg"
            max-width="200"
            contain
            class="mx-auto"
        ></v-img>
      </v-container>
    </v-app-bar>

    <!-- Main Content -->
    <v-main style="z-index: 4">
      <v-container class="fill-height" fluid>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.background-container {
  position: relative;
  background-image: url('https://images.unsplash.com/photo-1592427161906-c1db4ba75bc0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.v-app-bar,
.v-main {
  position: relative;
  z-index: 1;
}
</style>